import { registerApplication, start } from "single-spa";
import {
  constructApplications,
  constructRoutes,
  constructLayoutEngine,
} from "single-spa-layout";
import unauthApplication from "./views/unauth/unauthenticated-mfe-layout.html";
import Cookies from "universal-cookie";
import { verifyToken } from "./utils/auth.utils";

const cookie = new Cookies();

const routes = constructRoutes(unauthApplication);
const applications = constructApplications({
  routes,
  loadApp: ({ name }) => {
    return System.import(name);
  },
});

const layoutEngine = constructLayoutEngine({
  routes,
  applications,
});

applications.forEach((app) => {
  registerApplication({
    name: app.name,
    app: app.app,
    activeWhen: () => true,
  });
});

const onTenantRedirect = async () => {
  if (location.pathname == "/tenantLogin") {
    const urlParams = new URLSearchParams(location.search);
    const token = urlParams.get("token");
    const refreshToken = urlParams.get("refreshToken");
    cookie.set("access_token", token);
    cookie.set("refresh_token", refreshToken);
    cookie.set("redirectFrom", document.referrer);
  }

  const valid = await verifyToken();
  if (valid) {
    return (window.location.href = "/");
  }
};

window.addEventListener("single-spa:before-routing-event", onTenantRedirect);

layoutEngine.activate();
start();
